.nanomonx-landing-page-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  h2 {
    font-family: "FatFrank", sans-serif;
    font-size: 40px;
    line-height: 44px;
    font-weight: 900;
    text-transform: none;
    color: var(--landing-page-heading-light);
    text-align: center;
    margin: 60px 0;
  }

  p.heading-desc {
    font-size: 20px;
    line-height: 23px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 40px;
    max-width: 700px;
    margin: 0 auto;
  }

  .landing-header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 500px;
    height: 80vh;
    background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/landing-left-bg-mobile.svg"),
      url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/landing-right-bg-mobile.svg"),
      linear-gradient(
        77.07deg,
        var(--landing-bg-1) 35.55%,
        var(--landing-bg-2) 94.28%
      );
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position:
      -90px bottom,
      right -90px,
      top left;
    background-size:
      276px 200px,
      277px 200px,
      auto;

    .landing-header-wave {
      position: absolute;
      width: 100%;
      height: 108px;
      left: 0;
      bottom: -60px;
      z-index: 10;
      overflow: hidden;
      z-index: 10;

      svg {
        display: none;

        &.landing-header-wave-mobile {
          display: block;
        }
      }

      @media screen and (min-width: 1025px) {
        position: absolute;
        width: 100%;
        height: 150px;
        left: 0;
        bottom: -1px;
        z-index: 10;
        overflow: hidden;

        svg {
          &.landing-header-wave-mobile {
            display: none;
          }

          &.landing-header-wave-desktop {
            display: block;
          }
        }
      }
    }

    .landing-header-inner {
      position: relative;
      display: inline-flex;
      flex-direction: column;
      width: 40%;
      height: 70%;
      min-width: 300px;
      max-width: 500px;
      text-align: center;
      z-index: 20;

      .connection-button {
        &[data-size="regular"] {
          display: none;
        }
        &[data-size="small"] {
          display: inline-flex;
        }
        @media screen and (min-width: 1001px) and (min-height: 800px) {
          &[data-size="small"] {
            display: none;
          }
          &[data-size="regular"] {
            display: inline-flex;
          }
        }
      }

      .main-heading-desc {
        display: flex;
        margin: 20px 0;
        padding: 0 20px;
        color: var(--landing-page-color2);
        flex: 1;
      }

      .scroll-link {
        width: 100%;

        p {
          font-size: 14px;
          font-weight: 800;
          color: var(--default-color-2);
          padding: 0;
          margin: 0;
        }

        a {
          width: 70px;
          display: inline-block !important;
          font-size: 40px;
          line-height: 40px;
          color: var(--landing-page-scroll-arrow);
          margin: 0 auto 30px;

          &:hover {
            color: var(--landing-page-login-btn-bg1);
          }
        }
      }
    }

    @media screen and (min-width: 601px) {
      height: 600px;
      height: 75vh;
      background-repeat: no-repeat, no-repeat, no-repeat;
      background-position:
        -80px bottom,
        right -100px,
        top left;
      background-size:
        373px 270px,
        416px 300px,
        auto;

      .landing-header-inner {
        height: 60%;
      }
    }

    @media screen and (min-width: 801px) {
      background-repeat: no-repeat, no-repeat, no-repeat;
      background-position:
        -60px bottom,
        right -60px,
        top left;
      background-size:
        373px 270px,
        416px 300px,
        auto;
    }

    @media screen and (min-width: 1001px) {
      background-repeat: no-repeat, no-repeat, no-repeat;
      background-position:
        -80px bottom,
        right -140px,
        top left;
      background-size:
        497px 360px,
        554px 400px,
        auto;
    }

    @media screen and (min-width: 1401px) and (min-height: 800px) {
      height: 800px;
      height: 90vh;
      max-height: 800px;
      background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/landing-left-bg.svg"),
        url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/landing-right-bg.svg"),
        linear-gradient(
          77.07deg,
          var(--landing-bg-1) 35.55%,
          var(--landing-bg-2) 94.28%
        );
      background-repeat: no-repeat, no-repeat, no-repeat;
      background-position:
        top left,
        top right,
        top left;
      background-size:
        763px 800px,
        604px 688px,
        auto;
    }

    @media screen and (min-width: 1641px) and (min-height: 800px) {
      height: 800px;
      height: 90vh;
      max-height: 900px;
      background-repeat: no-repeat, no-repeat, no-repeat;
      background-position:
        top left,
        top right,
        top left;
      background-size:
        859px 900px,
        680px 774px,
        auto;
    }
  }

  .landing-products {
    width: 100%;
    background-color: var(--default-bg);
    padding: 0 40px 40px 40px;

    .products-heading-title {
      color: var(--landing-page-heading-light);
    }

    .products-heading-desc {
      color: var(--landing-page-color1);
    }

    .product-cards-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      margin: 40px auto;
      width: 100%;
      gap: 20px;

      .product-card {
        width: 320px;
        aspect-ratio: 328 / 334;
        align-self: stretch;

        .card-flipper {
          position: relative;
          transition: 0.6s ease-in-out;
          transform-style: preserve-3d;
          width: 100%;
          height: 100%;

          .card-inner {
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
            color: var(--default-color-2);
            width: 100%;
            height: 100%;
            padding: 25px;
            border-radius: 15px;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;

            &.card-front {
              cursor: pointer;
              z-index: 2;
              transform: rotateY(0deg);

              img {
                width: 100%;
                max-width: 60%;
              }

              h4 {
                color: var(--default-color-2);
                text-align: center;
                font-family: "Sofia Pro";
                font-size: clamp(18px, 3vw, 24px);
                font-weight: 900;
                line-height: 1em;
                text-transform: none;
                margin: 0;
              }
            }
            &.card-back {
              transform: rotateY(180deg);

              .close-card-btn {
                cursor: pointer;
                position: absolute;
                left: 5px;
                top: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-color: var(--default-bg);

                i,
                svg {
                  font-size: 20px;
                  color: var(--troubadour-main-5);
                }

                &:hover {
                  filter: brightness(1.1);
                }
              }

              .card-text {
                color: var(--default-color-2);
                font-family: "Sofia Pro";
                font-size: clamp(14px, 2vw, 18px);
                font-weight: 400;
                line-height: 1.3em;
                padding: 20px 0;
                flex: 1;
              }

              a,
              button {
                margin: auto 0 0;
                align-self: center;
              }
            }
          }
        }

        &.flipped {
          .card-flipper {
            transform: rotateY(180deg);
          }
        }

        &[data-product="TROUBADOUR"] {
          .card-inner {
            background-color: var(--troubadour-main-5);
            &.card-front {
              h4 {
                color: var(--troubadour-main-7);
                margin-top: -5px;
              }
            }
          }
        }

        &[data-product="FAMILY"] {
          .card-inner {
            background: var(--troubadour-section-bg2);
            &.card-front {
              h4 {
                margin-top: -5px;
              }
            }
          }
        }

        &[data-product="PARTNERS"] {
          .card-inner {
            background-color: var(--admin-3);
          }
        }

        &[data-product="CONSTELLATION"] {
          .card-inner {
            background-color: var(--constellation-main-2);
          }
        }
      }

      @media screen and (max-width: 1600px) {
        max-width: 980px;
      }
    }
  }

  .landing-expertise {
    width: 100%;
    padding: 0 40px;
    background-color: var(--landing-bg-2);

    .expertise-heading-title {
      color: var(--landing-page-heading-dark);
    }

    .expertise-heading-desc {
      color: var(--landing-page-color2);
    }

    .expertise-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 240px;
      margin: 60px auto;
      padding: 0;
      gap: 20px;
      list-style-type: none;

      li {
        display: flex;
        flex: 1 0 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          max-height: 125px;
          margin-bottom: 15px;
        }

        span {
          font-family:
            Sofia Pro,
            sans-serif;
          font-size: clamp(16px, 2vw, 18px);
          line-height: 1.2em;
          color: var(--landing-page-color2);
          text-align: center;
        }
      }

      @media screen and (min-width: 601px) {
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 600px;

        li {
          flex: 1 0 calc(50% - 10px);
        }
      }

      @media screen and (min-width: 1401px) {
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 1200px;

        li {
          flex: 1 0 calc(25% - 15px);
        }
      }
    }
  }

  @media screen and (min-width: 801px) {
    h2 {
      font-size: 48px;
      line-height: 52px;
    }

    p.heading-desc {
      font-size: 24px;
      line-height: 27px;
    }

    .landing-header {
      .landing-header-inner {
        .scroll-link {
          p {
            font-size: 16px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1001px) and (min-height: 800px) {
    h2 {
      font-size: 52px;
      line-height: 56px;
    }

    p.heading-desc {
      font-size: 26px;
      line-height: 29px;
    }

    .landing-header {
      .landing-header-inner {
        .scroll-link {
          p {
            font-size: 18px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1641px) and (min-height: 800px) {
    h2 {
      font-size: 58px;
      line-height: 62px;
    }

    p.heading-desc {
      font-size: 30px;
      line-height: 33px;
    }

    .landing-header {
      .landing-header-inner {
        .scroll-link {
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
}

.invalid-credential {
  color: var(--content-invalid);
  margin: 0 0 5px;
}

.account-popup--success {
  .account-popup();
  background-color: var(--account-creation-bg);
}

.account-popup() {
  position: absolute;
  z-index: 500;
  text-align: center;
  left: 0;
  right: 0;
  top: -50px;
  margin-left: auto;
  margin-right: auto;

  width: 45%;
  color: var(--default-color-2);
  border-radius: 6px;
  opacity: 0;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  animation: fadeIn 0.4s forwards cubic-bezier(0.37, 0.24, 0.42, 1.4);
  animation-delay: 0.5s;

  @keyframes fadeIn {
    from {
      opacity: 0;
      top: -50px;
    }

    to {
      opacity: 1;
      top: 25px;
    }
  }

  .content {
    padding: 21px;

    h2 {
      color: var(--default-color-2);
      font-size: 22px;
      line-height: 24px;
      font-weight: bold;
      margin: 0;
    }

    p {
      font-size: 16px;
      margin: 5px 0 0 0;

      span {
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }

  .button-close {
    i {
      font-size: 16px;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    position: absolute;
    top: 0;
    padding: 10px 15px;
    right: 0;
  }

  .button-close i:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}
